import { roles } from './roles';
import store from '../store/store';

// roles to be redirected to dashboard if logged in
const toDashboard = [
  'Administrator',
  'planting_trees',
  'planting_trees_admin',
  'Supervisor',
  'COS',
  'Chief',
  'Regular',
  'NstpFocal',
];

export function canAccess(path, role) {
  if (role === undefined || path === undefined) return false;
  return roles[role]?.paths.includes(path);
}

export function checkNavigationGuards(to, from, next) {
  if (to.name == 'CavStatusTracker') next();
  else if (to.name == 'SubmitProgramApplication') next();
  else if (to.name !== 'Login' && !store.state.isAuthenticated)
    next({ name: 'Login' });
  else if (
    to.name == 'Login' &&
    store.state.isAuthenticated &&
    toDashboard.includes(store.state.user.role)
  )
    next({ name: 'Dashboard' });
  else if (
    to.name == 'Login' &&
    store.state.isAuthenticated &&
    store.state.user.role == 'Registrar'
  )
    next({ name: 'CAVApplication' });
  else if (
    to.name == 'Login' &&
    store.state.isAuthenticated &&
    store.state.user.role == 'Records'
  )
    next({ name: 'RecordsDashboard' });
  else if (
    to.name == 'Login' &&
    store.state.isAuthenticated &&
    store.state.user.role == 'HR'
  )
    next({ name: 'hr_dashboard' });
  else next();
}
