import Vue from 'vue';

const moment = require('moment-business-days');
window.moment = moment;

Vue.filter("reviewDate", function (created) {
    return moment(created).format("M/D/Y h:mm A");
});

Vue.filter("standardDate", function (created) {
    return moment(created).format("LL"); //January 2, 2021
});

Vue.filter("shortDate", function (created) {
    return moment(created).format("L"); // 01/22/2022
});

Vue.filter('dateTime', function (created){
    return moment(created).format("lll"); // 01/22/2022
});

Vue.filter('completeDateTime', function (created){
    return moment(created).format("llll"); // 01/22/2022
});

Vue.filter("yearOnly", function (created) {
    return moment(created).format("YYYY"); // 2021
});

Vue.filter("duration", function (created) {
    return moment(created).fromNow(); // 2021
});

Vue.filter("addBusinessDays", function (created, days) {
    let deadline = moment(created).businessAdd(days) // 2021
    return moment(deadline).format('LLL');
});

Vue.filter("dueIn", function(deadline){
    let now = moment(); 
    let due = moment(deadline);
    return now.to(due);
})

Vue.filter('prettyBytes', function (num) {
    
    if (typeof num !== 'number' || isNaN(num)) {
      throw new TypeError('Expected a number');
    }
      
    var exponent;
    var unit;
    var neg = num < 0;
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
    if (neg) {
      num = -num;
    }
  
    if (num < 1) {
      return (neg ? '-' : '') + num + ' B';
    }
  
    exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    unit = units[exponent];
  
    return (neg ? '-' : '') + num + ' ' + unit;
  });
