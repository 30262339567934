export default {
  user: null,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isAuthenticated: false,
  hasIncomingDocuments: false,
  hasSerialNumberRequests: false,
  heis: [],
  programs: [],
  isProgramsMounted: false,
};
